<script>
import axios from "axios";
import ModalEditStatus from "./edit-simulasi-status.vue";
import ModalAddDurasi from "./edit-simulasi-durasi.vue";

export default {
  props: ["kelompok", "id_simulasi", "intake_type"],
  components: {
    ModalEditStatus,
    ModalAddDurasi,
  },
  data() {
    return {
      data_kelompok: [],
      form_edit_status: false,
      selected_data: "",
      form_add_durasi: false,
    };
  },
  mounted() {
    this.getKelompokPeserta();
  },
  methods: {
    getKelompokPeserta() {
      const apiUrl =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        `monitoring/kelompok-peserta/${this.$route.params.id}/${this.id_simulasi}/${this.kelompok}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var responseData = response.data;
          this.data_kelompok = responseData.data.data;
          console.log(this.data_kelompok);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    showStatusModal(data) {
      let self = this;
      self.form_edit_status = !self.form_edit_status;
      this.selected_data = data;
    },
    showDurasiModal(data) {
      let self = this;
      self.form_add_durasi = !self.form_add_durasi;
      this.selected_data = data;
    },
    redirectHalamanEvidence(asesi) {
      let url =
        process.env.VUE_APP_OASE_EVIDENCE_PTL_URL +
        asesi.jadwal_id +
        "?detail=" +
        asesi.id +
        "&tipe=ptl";
      window.open(url, "_blank");
    },
  },
};
</script>
<template>
  <div class="table-responsive mt-2">
    <h6>{{ kelompok }}</h6>
    <table class="table mb-0 table-bordered table-hover">
      <thead class="bg-dark text-white">
        <tr>
          <th class="p-2">Pin</th>
          <th class="p-2">Nip</th>
          <th class="p-2">Nama</th>
          <th class="p-2">Asesor</th>
          <th class="p-2">Status Pengerjaan</th>
          <th class="p-2">Sisa Waktu</th>
          <th class="p-2">Aksi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(asesi, key) in data_kelompok" :key="key">
          <td class="p-2">{{ asesi.pin }}</td>
          <td class="p-2">{{ asesi.nip_asesi }}</td>
          <td class="p-2">{{ asesi.nama_asesi }}</td>
          <td class="p-2">{{ asesi.asesor_master.u_nama }}</td>
          <td class="p-2">
            <b-badge
              v-if="asesi.status == 'berlangsung'"
              pill
              variant="warning"
            >
              {{ asesi.status }}
            </b-badge>
            <b-badge v-else-if="asesi.status == 'belum'" pill variant="danger">
              {{ asesi.status }}
            </b-badge>
            <b-badge v-else pill variant="success">
              {{ asesi.status }}
            </b-badge>
            <br/>
            <button class="btn btn-sm btn-info" @click="showStatusModal(asesi)">
              <i class="fa fa-edit"></i>
            </button>
          </td>
          <td class="p-2">
            {{ asesi.durasi ? asesi.durasi - asesi.durasi_terpakai : 0 }} menit
            <button
              class="btn btn-sm btn-danger"
              @click="showDurasiModal(asesi)"
            >
              <i class="fa fa-edit"></i>
            </button>
          </td>
          <td class="p-2">
            <div class="btn-group">
              <router-link
                :to="{
                  name: 'monitoring-admin-detail',
                  params: {
                    id_simulasi: asesi.simulasi_id,
                    nip_asesi: asesi.nip_asesi,
                    intake_type: intake_type,
                    id_jadwal_detail: asesi.id,
                    posisi : 'PTL'
                  },
                }"
                class="btn btn-info btn-sm"
                ><i class="fas fa-eye"></i> Detail</router-link
              >
              <!-- <button type="button" class="btn btn-primary btn-sm" @click="redirectHalamanEvidence(asesi)">
                <i class="fas fa-undo"></i> Evidence
              </button> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <b-modal
    v-if="form_edit_status"
    v-model="form_edit_status"
    id="modal-lg"
    title="Ubah Status"
    size="md"
    title-class="font-18"
    hide-footer
  >
    <ModalEditStatus :simulasi_data="selected_data" />
  </b-modal>

  <b-modal
    v-if="form_add_durasi"
    v-model="form_add_durasi"
    id="modal-lg"
    title="Tambah Durasi"
    size="md"
    title-class="font-18"
    hide-footer
  >
    <ModalAddDurasi :simulasi_data="selected_data" />
  </b-modal>
</template>
